
import CompTable from "@/components/comp-table.vue"
import CompMenu from "@/components/comp-menu.vue"
import ElemIcon from "@/components/elem-icon.vue"
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"
import CompTransfer from "./CompTransfer.vue"
import CompMeal from "./CompMeal.vue"

import Message from "@/module/interactive/message"
import Request from "@/module/request/request"

import Employee from "@/entity/employee"

export default {
    components: {
        CompTable,
        CompMenu,
        ElemIcon,
        CompModal,
        ElemForm,
        CompTransfer,
        CompMeal,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "车辆编号",
                    key: "vehicleNum",
                },
                {
                    title: "状态",
                    width: 130,
                    render: (h, params) => {
                        if (params.row.accountBalance < 0) {
                            return "欠费"
                        }
                        return { 0: "正常", 1: "欠费", 2: "已归档", 3: "已转出" }[params.row.state] || "未知"
                    },
                },
                {
                    title: "车架号",
                    key: "vehicleFrameNum",
                },
                {
                    title: "车牌号",
                    key: "vehiclePlateNum",
                },
                {
                    title: "司机",
                    minWidth: 150,
                    slot: "row-driver",
                },
                {
                    title: "账户余额",
                    render: (h, params) => {
                        return "￥" + (!params.row.accountBalance ? "0.00" : (params.row.accountBalance / 100).toFixed(2))
                    },
                },
                {
                    title: "加入时长",
                    render: (h, params) => {
                        return new Date().getFullYear() - new Date(params.row.createdDate).getFullYear() + 1 + "年"
                    },
                },
                {
                    title: "套餐有效期",
                    key: "maturityDate",
                },
                {
                    title: "是否启用",
                    render: (h, params) => {
                        return params.row.enabled ? "已启用" : "未启用"
                    },
                },
                {
                    title: "变更申请状态",
                    render: (h, params) => {
                        const list = params.row.vehicleChangeList

                        if (!list?.length) {
                            return "-"
                        }

                        const last = list[list.length - 1]

                        const name =
                            {
                                VEHICLE_CHANGE: "更换套餐",
                                VEHICLE_TRANSFER: "车辆转出",
                                VEHICLE_ARCHIVE: "车辆归档",
                            }[last.type] || "申请"

                        const state = {
                            0: "等待审核",
                            1: "审核通过",
                            2: "已拒绝",
                            3: "取消申请",
                        }[last.approvalStatus]

                        return name + " - " + state
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 380,
                    slot: "row-operate",
                },
            ],

            recharge_structure: [
                {
                    field: "amount",
                    name: "amount",
                    title: "充值金额",
                    type: "Price",
                    required: true,
                },
                {
                    field: "confirm_amount",
                    name: "confirm_amount",
                    title: "确认充值金额",
                    type: "Price",
                    required: true,
                },
            ],

            // 维护保养表单结构
            maintaining_structure: [
                {
                    field: "type",
                    name: "type",
                    title: "类型",
                    required: true,
                    type: "Select",
                    selectConfig: {
                        data: [
                            {
                                id: "1",
                                value: "维护保养",
                            },
                            {
                                id: "2",
                                value: "年审",
                            },
                        ],
                    },
                },
                {
                    field: "project",
                    name: "project",
                    title: "维护项目",
                    required: true,
                    type: "Input",
                },
                {
                    field: "cost",
                    name: "cost",
                    title: "费用",
                    required: true,
                    type: "Price",
                },
                {
                    field: "date",
                    name: "date",
                    title: "日期",
                    required: false,
                    type: "Date",
                },
                {
                    field: "remark",
                    name: "remark",
                    title: "备注",
                    required: false,
                    type: "Input",
                },
            ],

            // 套餐变更车辆
            meal_vehicle: null,
        }
    },

    methods: {
        refresh() {
            this.$refs.comp_table.refresh()
        },

        onRecharge(item: obj) {
            // 缓存充值车辆
            this.recharge_vehicle = item
            // 显示充值弹窗
            this.$refs.recharge.display()
        },

        async onSubmitRecharge() {
            const res = await this.$refs.recharge_form.getFormData()

            if (res.amount !== res.confirm_amount) {
                return Message.error("充值金额与确认充值金额不一致")
            }

            Message.info("是否确认充值，车牌号为：" + (this.recharge_vehicle.vehiclePlateNum || "暂无车牌号"), false)
                .onConfirm(() => {
                    Request.post("WEB://Vehicle/ManualRecharge", {
                        vehicle: this.recharge_vehicle.uuid,
                        amount: res.amount,
                    }).then(() => {
                        Message.success("充值成功")
                        this.$refs.comp_table.refresh()
                    })
                    this.$refs.recharge.close()
                })
                .build()
        },

        onCloseRecharge() {
            this.$refs.recharge_form.clear()
        },

        onSelectDriver(evt: obj) {
            console.log(evt)
        },

        /**
         * 归档
         */
        onPigeonhole(item: obj) {
            Message.info("是否确认归档，车牌号为：" + item.vehiclePlateNum, false)
                .onConfirm(() => {
                    this.onSubmitChange("VEHICLE_ARCHIVE", {
                        vehicle: item.uuid,
                    })
                })
                .build()
        },

        /**
         * 显示套餐变更弹窗
         */
        onDisplayMealChange(item: obj) {
            this.$refs.comp_meal.display(item)
        },

        /**
         * 显示车辆转出弹窗
         */
        onDisplayTransfer(item: obj) {
            this.$refs.comp_transfer.display(item)
        },

        getDrivers(value) {
            var menus = [
                {
                    title: "主司机",
                    sub: [
                        {
                            id: "mainDriver",
                            icon: "user",
                            name: value.mainDriver.username,
                        },
                    ],
                },
            ]

            if (value.drivers?.length) {
                menus.push({
                    title: "其他司机",
                    sub: value.drivers.map(driver => {
                        return {
                            id: driver.uuid,
                            icon: "user",
                            name: driver.username,
                        }
                    }),
                })
            }

            return menus
        },

        onDisplayDetail(uuid: string) {
            this.$refs.comp_table.detail(uuid)
        },

        /** 显示维护保养表单 */
        onDisplayMaintainingForm(item: obj) {
            this.maintaining_vehicle = item
            this.$refs.maintaining.display()
        },

        /** 提交维护保养表单 */
        async onSubmitMaintaining() {
            const data = await this.$refs.maintaining_form.getFormData()

            Request.post(
                "WEB://Maintenance/Save",
                {
                    ...data,
                    vehicle: this.maintaining_vehicle.uuid,
                },
                {
                    success: "提交成功",
                }
            ).then(() => {
                this.$refs.maintaining.close()
                this.$refs.comp_table.refresh()
            })
        },

        /** 关闭维护保养表单 */
        onCloseMaintaining() {
            this.$refs.maintaining_form.clear()
        },

        isAdmin() {
            return Employee.isAdmin()
        },

        /**
         * 是否正在等待更换套餐审批
         */
        isApproval(item: obj) {
            if (!item.vehicleChangeList?.length) {
                return false
            }

            const last = item.vehicleChangeList[item.vehicleChangeList.length - 1]

            return last.approvalStatus === "0"
        },

        getApprovalName(item: obj) {
            return {
                VEHICLE_CHANGE: "更换套餐",
                VEHICLE_TRANSFER: "车辆转出",
                VEHICLE_ARCHIVE: "车辆归档",
            }[item.vehicleChangeList[item.vehicleChangeList.length - 1].type]
        },

        /**
         * 取消套餐变更申请
         */
        onCancelChange(item: obj) {
            Request.post("WEB://VehicleChange/Cancel", {
                uuid: item.vehicleChangeList[item.vehicleChangeList.length - 1].uuid,
            }).then(() => {
                Message.success("取消成功")
                this.$refs.comp_table.refresh()
            })
        },

        /**
         * 提交车辆变更申请
         */
        async onSubmitChange(type: "VEHICLE_TRANSFER" | "VEHICLE_ARCHIVE", data: obj) {
            Request.post("WEB://VehicleChange/Save", {
                ...data,
                type: type,
            }).then(() => {
                Message.success("提交成功，请等待审核！")
                // 关闭弹窗
                this.$refs.meal.close()
                // 事件
                this.$emit("on-submit", {
                    tag: "CompMeal",
                })
            })
        },
    },
}
