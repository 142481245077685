import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vehicle-table" }
const _hoisted_2 = {
  key: 0,
  class: "driver-box"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_elem_icon = _resolveComponent("elem-icon")!
  const _component_comp_menu = _resolveComponent("comp-menu")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_block = _resolveComponent("block")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_comp_table = _resolveComponent("comp-table")!
  const _component_elem_form = _resolveComponent("elem-form")!
  const _component_comp_modal = _resolveComponent("comp-modal")!
  const _component_CompTransfer = _resolveComponent("CompTransfer")!
  const _component_CompMeal = _resolveComponent("CompMeal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: "车辆管理",
      entity: "Vehicle",
      columns: $data.columns,
      tableApi: "WEB://Vehicle/FindAllToPage"
    }, {
      search: _withCtx((evt) => [
        _createVNode(_component_Select, {
          modelValue: evt.search.state,
          "onUpdate:modelValue": ($event: any) => ((evt.search.state) = $event),
          placeholder: "状态"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Option, { value: "0" }, {
              default: _withCtx(() => [
                _createTextVNode("正常")
              ]),
              _: 1
            }),
            _createVNode(_component_Option, { value: "1" }, {
              default: _withCtx(() => [
                _createTextVNode("欠费")
              ]),
              _: 1
            }),
            _createVNode(_component_Option, { value: "2" }, {
              default: _withCtx(() => [
                _createTextVNode("已归档")
              ]),
              _: 1
            }),
            _createVNode(_component_Option, { value: "3" }, {
              default: _withCtx(() => [
                _createTextVNode("已转出")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.vehiclePlateNum,
          "onUpdate:modelValue": ($event: any) => ((evt.search.vehiclePlateNum) = $event),
          placeholder: "车牌号"
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.vehicleAge,
          "onUpdate:modelValue": ($event: any) => ((evt.search.vehicleAge) = $event),
          placeholder: "车龄"
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.introducer,
          "onUpdate:modelValue": ($event: any) => ((evt.search.introducer) = $event),
          placeholder: "介绍人"
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        _createVNode(_component_Input, {
          modelValue: evt.search.creatorId,
          "onUpdate:modelValue": ($event: any) => ((evt.search.creatorId) = $event),
          placeholder: "登记人"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      operate: _withCtx(() => [
        _createVNode(_component_Button, {
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/vehicle/form')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("新增")
          ]),
          _: 1
        })
      ]),
      "row-driver": _withCtx((params) => [
        (params.row.mainDriver)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(params.row.mainDriver.username), 1),
              _createVNode(_component_elem_icon, {
                class: "icon",
                name: "arrow_bottom"
              }),
              _createVNode(_component_comp_menu, {
                value: $options.getDrivers(params.row),
                onSelect: $options.onSelectDriver
              }, null, 8, ["value", "onSelect"])
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))
      ]),
      "row-operate": _withCtx((params) => [
        _createVNode(_component_Button, {
          type: "primary",
          style: {"margin":"3px"},
          onClick: ($event: any) => ($options.onDisplayDetail(params.row.uuid))
        }, {
          default: _withCtx(() => [
            _createTextVNode("详情")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        ($options.isAdmin())
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "info",
              style: {"margin":"3px"},
              onClick: ($event: any) => (_ctx.$router.push('/vehicle/form?id=' + params.row.uuid))
            }, {
              default: _withCtx(() => [
                _createTextVNode("编辑")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        ($options.isAdmin())
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              type: "error",
              style: {"margin":"3px"},
              onClick: ($event: any) => (params.event.delete({ uuid: params.row.uuid }))
            }, {
              default: _withCtx(() => [
                _createTextVNode("删除")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        (params.row.state !== '2' && params.row.state !== '3')
          ? (_openBlock(), _createBlock(_component_Dropdown, { key: 2 }, {
              list: _withCtx(() => [
                _createVNode(_component_DropdownMenu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DropdownItem, {
                      onClick: ($event: any) => ($options.onRecharge(params.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("充值")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    (params.row.state === '0')
                      ? (_openBlock(), _createBlock(_component_DropdownItem, {
                          key: 0,
                          onClick: ($event: any) => ($options.onDisplayMaintainingForm(params.row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("维护保养")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    ($options.isApproval(params.row))
                      ? (_openBlock(), _createBlock(_component_DropdownItem, {
                          key: 1,
                          onClick: ($event: any) => ($options.onCancelChange(params.row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("取消" + _toDisplayString($options.getApprovalName(params.row)) + "申请", 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_block, { key: 2 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DropdownItem, {
                              onClick: ($event: any) => ($options.onDisplayMealChange(params.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("更改套餐")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_DropdownItem, {
                              onClick: ($event: any) => ($options.onPigeonhole(params.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("归档")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_DropdownItem, {
                              onClick: ($event: any) => ($options.onDisplayTransfer(params.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("转出")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "success",
                  style: {"margin":"3px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 更多 "),
                    _createVNode(_component_Icon, { type: "ios-arrow-down" })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns"]),
    _createVNode(_component_comp_modal, {
      ref: "recharge",
      title: "充值",
      height: "auto",
      "on-success": $options.onSubmitRecharge,
      onOnClose: $options.onCloseRecharge
    }, {
      default: _withCtx(() => [
        _createVNode(_component_elem_form, {
          ref: "recharge_form",
          structure: $data.recharge_structure
        }, null, 8, ["structure"])
      ]),
      _: 1
    }, 8, ["on-success", "onOnClose"]),
    _createVNode(_component_comp_modal, {
      ref: "maintaining",
      title: "维护保养",
      height: "auto",
      "on-success": $options.onSubmitMaintaining,
      onOnClose: $options.onCloseMaintaining
    }, {
      default: _withCtx(() => [
        _createVNode(_component_elem_form, {
          ref: "maintaining_form",
          structure: $data.maintaining_structure
        }, null, 8, ["structure"])
      ]),
      _: 1
    }, 8, ["on-success", "onOnClose"]),
    _createVNode(_component_CompTransfer, { ref: "comp_transfer" }, null, 512),
    _createVNode(_component_CompMeal, {
      ref: "comp_meal",
      onOnSubmit: $options.refresh
    }, null, 8, ["onOnSubmit"])
  ]))
}