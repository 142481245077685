
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"
import Request from "@/module/request/request"
import Message from "@/module/interactive/message"

export default {
    components: {
        CompModal,
        ElemForm,
    },

    data() {
        return {
            // 车辆
            vehicle: null,
            // 套餐列表
            meals: null,
            // 套餐表单结构
            meal_structure: null,
            // 表单数据
            formdata: {
                setMeal: null,
                newSetMeal: null,
            },
        }
    },

    methods: {
        async display(vehicle: obj) {
            if (!this.meals) {
                await this.getAllMeal()
            }

            this.vehicle = vehicle
            this.formdata.setMeal = vehicle.setMeal.uuid
            this.formdata.newSetMeal = null
            this.$refs.meal.display()
        },

        /**
         * 获取所有套餐
         */
        async getAllMeal() {
            await Request.get("WEB://SetMeal/FindAllToList").then((res: obj[]) => {
                this.meals = res

                const meals_value = res.map(v => ({
                    id: v.uuid,
                    value: v.setMealName,
                }))

                this.meal_structure = [
                    {
                        field: "setMeal",
                        name: "setMeal",
                        title: "原有套餐",
                        required: true,
                        type: "Select",
                        selectConfig: {
                            field: null,
                            controller: true,
                            data: meals_value,
                            selectField: {},
                            multiple: false,
                            type: "com.jemmy.transport.business.setmeal.SetMeal",
                        },
                    },
                    {
                        field: "newSetMeal",
                        name: "newSetMeal",
                        title: "新套餐",
                        required: true,
                        type: "Select",
                        selectConfig: {
                            field: null,
                            controller: true,
                            data: meals_value,
                            selectField: {},
                            multiple: false,
                            type: "com.jemmy.transport.business.setmeal.SetMeal",
                        },
                    },
                ]
            })
        },

        getPrice(price: number) {
            return "￥" + (price ? (price / 100).toFixed(2) : "0.00")
        },

        getMealPrice(uuid: string) {
            const meal = this.meals?.find(v => v.uuid === uuid)
            return this.getPrice(meal?.setMealAmount)
        },

        async onSubmit() {
            const setMeal = this.formdata.newSetMeal

            if (!setMeal) {
                return Message.error("请选择新套餐")
            }

            Request.post("WEB://VehicleChange/Save", {
                vehicle: this.vehicle.uuid,
                newSetMeal: setMeal,
                type: "VEHICLE_CHANGE",
            }).then(() => {
                Message.success("提交成功，请等待审核！")
                // 关闭弹窗
                this.$refs.meal.close()
                // 事件
                this.$emit("on-submit", {
                    tag: "CompMeal",
                })
            })
        },
    },
}
