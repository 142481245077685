
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"
import Request from "@/module/request/request"
import Message from "@/module/interactive/message"

export default {
    components: {
        CompModal,
        ElemForm,
    },

    data() {
        return {
            // 车辆
            vehicle: null,
            // 表单结构
            structure: [
                {
                    field: "buyerName",
                    name: "buyerName",
                    title: "购买人姓名",
                    required: true,
                    type: "Input",
                },
                {
                    field: "transferAmount",
                    name: "transferAmount",
                    title: "转出手续费",
                    required: true,
                    type: "Price",
                },
            ],
            // 表单数据
            formdata: {},
        }
    },

    methods: {
        async display(vehicle: obj) {
            this.vehicle = vehicle
            this.$refs.modal.display()
        },

        getPrice(price: number) {
            return "￥" + (price ? (price / 100).toFixed(2) : "0.00")
        },

        async onSubmit() {
            const formdata = this.formdata

            if (!formdata.buyerName) {
                return Message.error("请输入购买人姓名！")
            }

            if (!formdata.transferAmount) {
                return Message.error("请输入转出手续费！")
            }

            Request.post("WEB://VehicleChange/Save", {
                ...formdata,
                vehicle: this.vehicle.uuid,
                type: "VEHICLE_TRANSFER",
            }).then(() => {
                Message.success("提交成功，请等待审核！")
                // 关闭弹窗
                this.$refs.modal.close()
                // 事件
                this.$emit("on-submit", {
                    tag: "CompMeal",
                })
            })
        },
    },
}
