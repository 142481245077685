import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_form = _resolveComponent("elem-form")!
  const _component_CompModal = _resolveComponent("CompModal")!

  return (_openBlock(), _createBlock(_component_CompModal, {
    class: "vehicle-transfer-modal",
    ref: "modal",
    title: "转出",
    height: "auto",
    "on-success": $options.onSubmit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_elem_form, {
        ref: "meal_form",
        value: $data.formdata,
        structure: $data.structure
      }, null, 8, ["value", "structure"])
    ]),
    _: 1
  }, 8, ["on-success"]))
}